import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import axios from 'axios';
import './weihnachts-menue.scss';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';
import NumberField from '../presentational/Fields/Number';
import Text from '../presentational/Fields/Text';
import Select from '../presentational/Fields/Select';
import Textarea from '../presentational/Fields/Textarea';
import Checkbox from '../presentational/Fields/Checkbox';
import BotField from '../presentational/Fields/BotField';
import { validateNumber, validateEmail, validateMinLength, phoneValidator, validateRequired, validateBotField } from '../container/MenuForm/validators';

const SERVICE_HOST = 'https://c8zvcxmjl3.execute-api.eu-central-1.amazonaws.com/master/contact';
const INITIAL_VALUES = {
  schweinefilet: 0,
  rinderroulade: 0,
  wildgulasch: 0,
  kartoffelgratinBig: 0,
  kartoffelgratinSmall: 0,
  pickup: '',
  firstname: '',
  lastname: '',
  street: '',
  zip: '',
  email: '',
  phone: '',
  notes: '',
  datenschutz: false,
};

function getErrorMessage(error) {
  switch (error) {
    case 'EMAIL_EXISTS_ERROR':
      return 'Mit dieser E-Mail Adresse wurde bereits eine Vorbestellung abgegeben.';
    default:
      return 'Leider konnte Ihre Vorbestellung nicht abgeschickt werden.';
  }
}

function WeihnachtsMenue({ location }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const formRef = useRef(null);

  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="Weihnachtsmenü Vorbestellung"
        description="Klicken Sie schnell und einfach Ihr Weihnachtsmenü zusammen."
      />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Weihnachtsmenü Vorbestellung</h1>
          <div className="row">
            <div className="col col--lg-12">
              {/* <p>
                Die Frist für die Vorbestellung ist leider abgelaufen. Wir bedanken uns auch dieses Jahr wieder für die zahlreichen Bestellungen und
                wünschen Ihnen und Ihren Liebsten ein frohes Weihnachtsfest und einen guten Rutsch ins neue Jahr.
              </p> */}
              <p>
                Jedes Gericht wird einen Tag vorher gekocht und für Sie frisch vakuumiert, dadurch haben Sie eine Haltbarkeit von mindestens 8 Tagen.
                Alle Gerichte können Sie am 23.12. von 08:00–18:00 Uhr oder am 24.12. von 07:00–12:00 Uhr bei uns abholen.<br />
                Frisches Wildfleisch, Bio-Rindfleisch und Bio-Hähnchen können Sie ebenfalls bei uns bestellen, dafür bitte Ihre Wünsche unter „Sonstiges“ eintragen.
              </p>
              <p>
                Wenn sie das Formular weiter unten auf dieser Seite nicht nutzen möchten, finden Sie hier den <a href="/pdf/Sauer_Weihnachtsflyer_2024.pdf" target="_BLANK">Fyler zum Drucken (PDF)</a>.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="christmas-menu">
        <div className="container" ref={(ref) => { formRef.current = ref; }}>
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={async (data, actions) => {
              try {
                actions.setSubmitting(true);
                setError(null);
                const { data: response } = await axios.post(SERVICE_HOST, data);
                console.log(response);
                setIsSubmitted(true);
                // eslint-disable-next-line no-unused-expressions
                formRef.current?.scrollIntoView({ behavior: 'smooth' });

                if (formRef.current) {
                  const offsetPosition = formRef.current.offsetTop - 110;
                  window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                  });
                }

                return;
              } catch (e) {
                console.error(e);
                setError(e.response?.data?.error || 'UNKNOWN_ERROR');
              }
            }}
          >
            {(formikProps) => {
              const { isSubmitting, values } = formikProps;
              return (
                <>
                  {isSubmitted && (
                    <>
                      <h1>Vielen Dank für Ihre Vorbestellung!</h1>
                      <p>Sie können Ihr Menü am <strong>{values.pickup}</strong> bei uns im Ladenlokal abholen.</p>
                      <p>Falls noch etwas Zeit ist, würden wir uns sehr über eine <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=Sauer+-+Catering+%26+Ladenlokal+am+Sorpesee&sca_esv=583929409&rlz=1C5CHFA_enDE1024DE1024&ei=OTpbZfjqDrGD9u8PpLu44A4&ved=0ahUKEwi466-ptNKCAxWxgf0HHaQdDuwQ4dUDCBA&uact=5&oq=Sauer+-+Catering+%26+Ladenlokal+am+Sorpesee&gs_lp=Egxnd3Mtd2l6LXNlcnAiKVNhdWVyIC0gQ2F0ZXJpbmcgJiBMYWRlbmxva2FsIGFtIFNvcnBlc2VlMgYQABgWGB5IuwtQ5gRY5gRwAngBkAEAmAFroAFrqgEDMC4xuAEDyAEA-AEC-AEBwgIKEAAYRxjWBBiwA-IDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x47b95e9c51eb2e0d:0xe55094ca27e073a5,1,,,,">Google Bewertung</a> freuen.</p>
                      <p>Ihr Team Sauer.</p>
                    </>
                  )}
                  {!isSubmitted && (
                    <form className={isSubmitting ? 'form--isSubmitting' : ''} onSubmit={formikProps.handleSubmit}>
                      <ul>
                        <li>
                          <div>
                            <h3>Schweinefilet</h3>
                            <span>in Pfeffersoße - <strong>p.P.&nbsp;9,00&nbsp;€</strong></span>
                          </div>
                          <NumberField
                            {...formikProps}
                            label="Anzahl"
                            name="schweinefilet"
                            validate={validateNumber('Bitte geben Sie eine gültige Zahl ein.')}
                          />
                        </li>
                        <li>
                          <div>
                            <h3>Rinderroulade</h3>
                            <span>mit klassischer Füllung und Sauce - <strong>p.P.&nbsp;9,00&nbsp;€</strong></span>
                          </div>
                          <NumberField
                            {...formikProps}
                            label="Anzahl"
                            name="rinderroulade"
                            validate={validateNumber('Bitte geben Sie eine gültige Zahl ein.')}
                          />
                        </li>
                        <li>
                          <div>
                            <h3>Wildgulasch</h3>
                            <span>mit Pilzen - <strong>p.P.&nbsp;9,00&nbsp;€</strong></span>
                          </div>
                          <NumberField
                            {...formikProps}
                            label="Anzahl"
                            name="wildgulasch"
                            validate={validateNumber('Bitte geben Sie eine gültige Zahl ein.')}
                          />
                        </li>
                        <li>
                          <div>
                            <h3>Kartoffelgratin</h3>
                            <span>für 6 Personen - <strong>18&nbsp;€</strong></span>
                          </div>
                          <NumberField
                            {...formikProps}
                            label="Anzahl"
                            name="kartoffelgratinBig"
                            validate={validateNumber('Bitte geben Sie eine gültige Zahl ein.')}
                          />
                        </li>
                        <li>
                          <div>
                            <h3>Kartoffelgratin</h3>
                            <span>für 3 Personen - <strong>9&nbsp;€</strong></span>
                          </div>
                          <NumberField
                            {...formikProps}
                            label="Anzahl"
                            name="kartoffelgratinSmall"
                            validate={validateNumber('Bitte geben Sie eine gültige Zahl ein.')}
                          />
                        </li>
                      </ul>

                      <Select
                        {...formikProps}
                        name="pickup"
                        label="Wann wollen Sie ihre Bestellung bei uns abholen?"
                        validate={validateRequired('Bitte wählen Sie eine Option')}
                        options={[
                          { value: '', label: 'Bitte wählen' },
                          { value: '23.12.', label: '23. Dezember' },
                          { value: '24.12.', label: '24. Dezember' },
                        ]}
                      />

                      <Text
                        {...formikProps}
                        name="firstname"
                        label="Vorname"
                        validate={validateMinLength('Bitte geben Sie Ihren Vornamen ein', 4)}
                      />

                      <Text
                        {...formikProps}
                        name="lastname"
                        label="Nachname"
                        validate={validateMinLength('Bitte geben Sie Ihren Nachnamen ein', 4)}
                      />

                      <Text
                        {...formikProps}
                        name="street"
                        label="Straße und Hausnummer"
                        validate={validateMinLength('Bitte geben Sie Ihre Straße und Hausnummer ein', 4)}
                      />

                      <Text
                        {...formikProps}
                        name="zip"
                        label="Postleitzahl und Ort"
                        validate={validateMinLength('Bitte geben Sie Ihre Postleitzahl und den Ort ein', 7)}
                      />

                      <Text
                        {...formikProps}
                        name="email"
                        type="email"
                        label="E-Mail Adresse"
                        validate={validateEmail('Bitte geben Sie Ihre E-Mail Adresse ein')}
                      />

                      <Text
                        {...formikProps}
                        name="phone"
                        type="tel"
                        label="Telefon"
                        validate={phoneValidator('Bitte geben Sie eine gültige Telefonnummer ein')}
                      />

                      <Textarea
                        {...formikProps}
                        name="notes"
                        label="Sonstiges"
                      />

                      <Checkbox
                        {...formikProps}
                        name="datenschutz"
                        onChange={formikProps.setFieldValue}
                        value={values.datenschutz}
                        label={(<span>Ich habe die <a target="_blank" href="/datenschutz">Datenschutzinformationen</a> zur Kenntnis genommen und bin einverstanden.</span>)}
                        validate={validateRequired('Bitte stimmen sie den Datenschutzinformationen zu')}
                      />

                      <BotField
                        {...formikProps}
                        name="bot"
                        onChange={formikProps.setFieldValue}
                        value={values.bot}
                        validate={validateBotField('Laden Sie die Seite erneut und lassen Sie das Formular nicht automatisch ausfüllen')}
                      />

                      {error && (
                        <p className="form__error">{getErrorMessage(error)}</p>
                      )}

                      <button className="button" type="submit" disabled={isSubmitting}>
                        Jetzt Vorbestellen
                      </button>
                    </form>
                  )}
                </>
              );
            }}
          </Formik>
        </div>
      </section>
    </Layout>
  );
}

WeihnachtsMenue.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default WeihnachtsMenue;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

function BotField(props) {
  const { registerField, unregisterField } = useFormikContext();
  const { onChange, value, name, errors, touched, validate } = props;

  useEffect(() => {
    registerField(name, { validate });
    return () => {
      unregisterField(name);
    };
  }, [registerField, unregisterField, name]);

  return (
    <div>
      <label tabIndex={-1} htmlFor={name} className="form__botField">
        <input
          type="input"
          value={value}
          name={name}
          tabIndex={-1}
          onChange={(e) => {
            onChange(name, e.target.value);
          }}
        />
      </label>
      {errors[name] && touched[name] && <div className="form__error">{errors[name]}</div>}
    </div>
  );
}

BotField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any.isRequired,
  touched: PropTypes.any.isRequired,
};

BotField.defaultProps = {
  validate: null,
  value: '',
};

export default BotField;
